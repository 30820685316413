/* eslint-disable react/prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-unresolved */
import theme from '@theme';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, {
  useContext,
  useEffect,
  useMemo,
} from 'react';
import styled, { css } from 'styled-components';
import { useQueryParam, StringParam, NumberParam } from 'use-query-params';

import {
  Footer,
  Modal,
  PageHeader,
  ChatSatisfi,
  ChatTrackhs,
  Link,
  OverlayWrapper,
  ContentBlockHero,
  UiSection,
} from '@powdr/components';
import {
  Components, QueryParamNames,
  ThemeProps as Prop,
  FontFamily,
  FontSize,
  Alignment,
  CarouselControlPlacement,
  NavigationDirection,
  Breakpoints,

} from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { useWindowResize } from '@powdr/hooks';
import {
  componentFactory,
  indexByColorProfile,
  isEmpty,
  componentColor,
  getFontStyles,
  getPageTheme,
} from '@powdr/utils';
import Popup from '@powdr/web/src/components/popup';
import { SEO } from '@powdr/web/src/components/seo';
import { useStaticData } from '@powdr/web/src/hooks';
import GlobalStyle from '@powdr/web/src/utils/global-styles';

// eslint-disable-next-line import/no-dynamic-require
const { gatsbyConfig } = require(`@powdr/${process.env.GATSBY_PROPERTY_NAME}`);

const SiteWrapper = styled.div``;

const StyledInnerPage = styled.div``;

const StyledMain = styled.main``;

const SkipToNavigation = styled.nav`
  display: flex;
  background-color: ${() => componentColor(Prop.BACKGROUND, Components.ALERT_BAR, Prop.BACKGROUND)};
  padding: 0 25px;
  gap: 25px;
  height: 0;
  position: static;

  a {
    font-size: 0;
  }

  &:focus-within {
    height: fit-content;

    a {
      ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG40, undefined, undefined)};
      margin: 2px;
      padding: 10px 5px;
      height: fit-content;
    }
  }
`;

const SkipToButton = styled(Link)`
  height: 0;
  color: ${() => componentColor(Prop.LINK_TXT, Components.ALERT_BAR, Prop.LINK_TXT)};
  ${() => getFontStyles(FontFamily.SECONDARY_FONT, FontSize.REG40, undefined, undefined)};

  &:focus, &:hover {
    outline: 2px solid ${() => componentColor(Prop.LINK_TXT_HOVER, Components.ALERT_BAR, Prop.LINK_TXT_HOVER)};
    color: ${() => componentColor(Prop.LINK_TXT_HOVER, Components.ALERT_BAR, Prop.LINK_TXT_HOVER)};
  }
`;

const VideoModalPositionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const FrameWrapper = styled.div`
  position: relative;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  width: 75%;
  padding-bottom: 42.1875%;

  .video-block-modal-frame {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const PrimaryCarouselSection = styled(UiSection)`
  .slider > div {
    .carousel-control-group {
      padding: 0 7%;
      justify-content: space-between;
    }

    .camp-image-carousel-previous, .camp-image-carousel-next {
      .control-arrow-icon {
        height: 40px;
        width: 40px;
        min-width: 40px;
      }
    }

    ${Breakpoints.mobile(css`
      height: 250px;
    `)}

    ${Breakpoints.larger(css`
      max-height: 750px;
    `)}
  }

`;

function Camp({
  data,
  uri,
}) {
  const currentPageData = data?.campContent?.nodes[0] || null;
  const [seasonParam, setSeasonParam] = useQueryParam(QueryParamNames.SEASON, StringParam);
  const [pageParam, setPageParam] = useQueryParam(QueryParamNames.PAGE, NumberParam);
  const [videoParam, setVideoParam] = useQueryParam(QueryParamNames.VIDEO, StringParam);
  const resize = useWindowResize();
  const {
    chatComponent,
    footerComponents,
    defaultSeason,
  } = useStaticData();
  const {
    themeConfig,
    fullHeaderHeight,
    setIsMobile,
    secondLevelNavHeight,
  } = useContext(AppContext);
  const pageTheme = getPageTheme(defaultSeason);

  if (!currentPageData || !theme) return null;

  useEffect(() => {
    if (seasonParam) {
      setSeasonParam(undefined);
    }
  }, [seasonParam, setSeasonParam]);

  /* Setting isMobile context variable */
  useEffect(() => {
    setIsMobile(!!(resize.windowWidth < 960));
  }, [resize.windowWidth, setIsMobile]);

  const levels = useMemo(
    () => uri.split('/').filter((level) => level !== ''),
    [uri],
  );

  const queryPageData = useMemo(
    () => ((pageParam)
      ? data.allGloballyAccessiblePages.nodes.find((obj) => obj.nodeID === pageParam)
      : null),
    [pageParam, data],
  );

  const components = currentPageData?.relationships;

  return (
    <>
      <GlobalStyle theme={pageTheme} />
      <SkipToNavigation aria-label="Accessibility Skip-To Navigation">
        <SkipToButton href="#footer">Skip to Footer Navigation</SkipToButton>
      </SkipToNavigation>
      <SiteWrapper>
        <OverlayWrapper
          levels={levels}
          showStickyNav={false}
          isPageModal={false}
        />
        <StyledMain
          id="main-content"
          $stickyNavHeight={secondLevelNavHeight}
        >
          <StyledInnerPage>
            <ContentBlockHero {...components?.hero} />
            <PageHeader
              headerHeight={fullHeaderHeight || 0}
              levels={levels}
              showStickyNav
            />

            {/* Intro */}
            {(components?.introSection) && (
              <UiSection
                relationships={{ children: [components?.introSection] }}
                topPadding={50}
                bottomPadding={50}
                sidePaddingPercentage={7.5}
              />
            )}

            {/* Primary Image Carousel */}
            {(components?.primaryCarousel) && (
            <PrimaryCarouselSection
              relationships={{
                children: [{
                  ...components?.primaryCarousel,
                  isThumbnailsDisabled: true,
                  additionalControls: {
                    isCenteredSlides: true,
                    slidesPerView: 1.5,
                    spaceBetweenSlides: 25,
                    controlSettings: {
                      navigation: {
                        placement: CarouselControlPlacement.ON_SLIDE,
                        iconName: 'ui-arrow-circle-left',
                        iconSize: 60,
                        classes: {
                          [NavigationDirection.PREVIOUS]: 'camp-image-carousel-previous',
                          [NavigationDirection.NEXT]: 'camp-image-carousel-next',
                        },
                      },
                    },
                  },
                }],
              }}
              isIgnoreMaxWidth
            />
            )}

            {/* Info Grid */}
            {(components?.infoGrid) && (
              <UiSection
                relationships={{ children: [components?.infoGrid] }}
                sidePaddingPercentage={7.5}
              />
            )}

            {/* Testimonial Section */}
            {(components?.testimonialCarousel) && (
              <UiSection
                colorProfile={2}
                sidePaddingPercentage={7.5}
                relationships={{
                  children: [
                    {
                      typeName: Components.UI_HEADER,
                      html: currentPageData?.testimonialCarouselHeader,
                      headerAlignment: Alignment.LEFT,
                    },
                    {
                      typeName: Components.CONTENT_BLOCK_CAROUSEL,
                      ...components?.testimonialCarousel,
                    },
                  ],
                }}
              />
            )}

            {/* Form Section */}
            {(components?.form) && (
              <UiSection
                relationships={{ children: [components?.form] }}
                topPadding={100}
                bottomPadding={100}
                sidePaddingPercentage={7.5}
              />
            )}

            {/* Stat Section */}
            {(components?.statGrid) && (
              <UiSection
                colorProfile={2}
                sidePaddingPercentage={7.5}
                relationships={{
                  children: [
                    {
                      typeName: Components.UI_HEADER,
                      html: currentPageData?.statGridHeader,
                    },
                    {
                      typeName: Components.GRID,
                      ...components?.statGrid,
                      rowGap: 150,
                      gridBottomPadding: 75,
                    },
                  ],
                }}
              />
            )}

            {/* Rates & Dates Section */}
            {(components?.ratesDates) && (
              <UiSection
                relationships={{
                  children: [
                    {
                      typeName: Components.UI_HEADER,
                      html: currentPageData?.ratesDatesHeader,
                      headerAlignment: Alignment.LEFT,
                      paddingBottom: 0,
                    },
                    {
                      typeName: Components.RATES_AND_DATES_TABS,
                      ...components?.ratesDates,
                    },
                  ],
                }}
                topPadding={100}
                sidePaddingPercentage={7.5}
              />
            )}

            {/* FAQ Section */}
            {(components?.faq) && (
              <UiSection
                relationships={{
                  children: [
                    {
                      typeName: Components.UI_HEADER,
                      html: currentPageData?.faqHeader,
                      headerAlignment: Alignment.LEFT,
                    },
                    {
                      typeName: Components.UI_COLUMN,
                      ...components?.faq,
                    },
                  ],
                }}
                topPadding={50}
                sidePaddingPercentage={7.5}
              />
            )}

            {/* Additional Info Section */}
            {(components?.additionalInfoGrid) && (
              <UiSection
                relationships={{
                  children: [
                    {
                      typeName: Components.UI_HEADER,
                      html: currentPageData?.additionalInfoGridHeader,
                      headerAlignment: Alignment.LEFT,
                      paddingBottom: 0,
                    },
                    {
                      typeName: Components.GRID,
                      ...components?.additionalInfoGrid,
                      gridBottomPadding: 150,
                    },
                    {
                      typeName: Components.UI_COLUMN,
                      ...components?.additionalInfoColumn,
                    },
                  ],
                }}
                bottomPadding={100}
                sidePaddingPercentage={7.5}
              />
            )}

          </StyledInnerPage>
        </StyledMain>
        {/* Additional Footer Components */}
        {(footerComponents) && componentFactory(
          footerComponents,
        )}
        <Footer
          id="footer"
          partners={data.allTaxonomyTermPartners.nodes}
          footerLinks={data.allTaxonomyTermFooterLinks.nodes}
          colorProfile={themeConfig?.[Components.FOOTER]}
        />
        {gatsbyConfig?.enableBrightEdgeTracking && (
          <div className="be-ix-link-block" />
        )}
        {/* Chat Component widgets */}
        {chatComponent.hasChatComponent && (
          <>
            {!isEmpty(chatComponent.chatSatisfi) && (
              <ChatSatisfi
                {...chatComponent.chatSatisfi}
              />
            )}
            {!isEmpty(chatComponent.chatTrackhs) && (
              <ChatTrackhs
                {...chatComponent.chatTrackhs}
              />
            )}
          </>
        )}
      </SiteWrapper>
      {/* Query parameter modal */}
      {queryPageData && (
        <Modal handleCloseModal={() => { setPageParam(undefined); }}>
          {componentFactory(
            queryPageData?.relationships?.pageContent || [],
            indexByColorProfile(themeConfig?.[Components.MODAL]),
          )}
        </Modal>
      )}
      {/* Video modal */}
      {videoParam && (
        <Modal handleCloseModal={() => { setVideoParam(undefined); }} isFullScreen>
          <VideoModalPositionWrapper>
            <FrameWrapper>
              <iframe
                title="modal-embedded-video"
                className="video-block-modal-frame"
                src={`https://www.youtube.com/embed/${videoParam}?autoplay=1&rel=0&wmode=transparent&modestbranding=1&autohide=1&showinfo=0`}
                allow="autoplay"
                allowFullScreen
              />
            </FrameWrapper>
          </VideoModalPositionWrapper>
        </Modal>
      )}
      <Popup />
    </>
  );
}

Camp.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
  pageContext: PropTypes.instanceOf(Object).isRequired,
  uri: PropTypes.string.isRequired,
};

export default Camp;

export const query = graphql`
  query CampQuery($nestedPaths: [String], $fullPath: String) {
    campContent: allNodeCamp(filter: { field_btn_href: { eq: $fullPath }, status: { eq: true } }) {
      ...NodeCampQuery
    }
    allGloballyAccessiblePages: allNodePage(filter: { field_globally_accessible: { eq: true }, status: { eq: true } }) {
      ...NodePageQuery
    }
    allTaxonomyTermFooterLinks {
      ...TaxonomyTermFooterLinksQuery
    }
    allTaxonomyTermPartners {
      ...TaxonomyTermPartnersQuery
    }
    paragraphHeaderConfiguration {
      ...HeaderConfigurationQuery
    }
    pageSettings: allTaxonomyTermSiteNavigation(
      filter: { field_navigation_link: { in: $nestedPaths } }
    ) {
      nodes {
        ...NavigationItemQuery
      }
    }
  }
`;

export const Head = ({
  location, data,
}) => (
  <SEO
    currentPageData={data?.campContent?.nodes[0]}
    location={location}
  />
);
